import { useState, useEffect } from 'react';

export const fetchImage = async (basePath: string, imagePath: string): Promise<string> => {
  try {
    const response = await fetch(`${basePath}/${imagePath}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    throw new Error(`Error loading image: ${imagePath}`);
  }
};

export const getSortedRelevantConditions = (findings: Record<string, number | string>) => {
  const classOrder: { [key: string]: number } = { 'likely': 0, 'possible': 1, 'unlikely': 2 };

  return Object.entries(findings)
    .filter(([key, value]) => key.startsWith('class_') && (value === 'likely' || value === 'possible'))
    .sort(([keyA, valueA], [keyB, valueB]) => {
      // First, compare class values
      const classA = valueA as string;
      const classB = valueB as string;
      if (classA in classOrder && classB in classOrder) {
        const classCompare = classOrder[classA] - classOrder[classB];
        if (classCompare !== 0) return classCompare;
      }

      // If class values are the same or not comparable, compare probabilities
      const probKeyA = keyA.replace('class_', '');
      const probKeyB = keyB.replace('class_', '');
      const probA = findings[probKeyA] as number;
      const probB = findings[probKeyB] as number;
      return probB - probA;
    })
    .map(([key]) => key.replace('class_', ''));
};

export const loadImages = async (cases: Record<string, Case>, basePath: string) => {
  for (const caseName of Object.keys(cases)) {
    const caseData = cases[caseName];
    const relevantConditions = getSortedRelevantConditions(caseData.findings);

    for (const condition of relevantConditions) {
      const imagePath = `${caseName}_${condition.replace('/', '_')}.png`;
      try {
        caseData.images[condition] = await fetchImage(basePath, imagePath);
      } catch (error) {
        console.error(`Failed to load image for ${condition} in case ${caseName}:`, error);
        // Don't set any value if the image fails to load
      }
    }
  }
};

export interface Case {
  name: string;
  findings: Record<string, number | string>;
  images: Record<string, string>;
  commentary: string;
}
export const getImage = (
  caseData: Case,
  selectedCondition: string | null
): string | null => {
  if (selectedCondition && caseData.images[selectedCondition]) {
    return caseData.images[selectedCondition];
  }

  const imageValues = Object.values(caseData.images);
  return imageValues.length > 0 ? imageValues[0] as string : null;
};

interface InitializeCasesOptions {
  casesUrl: string;
  commentariesUrl?: string;
  imagesPath: string;
}

interface InitializeCasesResult {
  cases: Record<string, Case>;
  isLoading: boolean;
  error: string | null;
}

export const useInitializeCases = ({ casesUrl, commentariesUrl, imagesPath }: InitializeCasesOptions): InitializeCasesResult => {
  const [cases, setCases] = useState<Record<string, Case>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeCases = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const [resultsJSON, commentariesJSON] = await Promise.all([
          fetch(casesUrl).then(response => {
            if (!response.ok) throw new Error('Failed to fetch cases');
            return response.json();
          }),
          commentariesUrl ? fetch(commentariesUrl).then(response => {
            if (!response.ok) throw new Error('Failed to fetch commentaries');
            return response.json();
          }) : Promise.resolve({}),
        ]);

        const initializedCases: Record<string, Case> = Object.entries(resultsJSON).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value as Case,
            commentary: commentariesUrl ? (commentariesJSON as Record<string, string>)[key] || '' : ''
          };
          return acc;
        }, {} as Record<string, Case>);

        await loadImages(initializedCases, imagesPath);

        setCases(initializedCases);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    initializeCases();
  }, [casesUrl, commentariesUrl, imagesPath]);

  return { cases, isLoading, error };
};

export default useInitializeCases;
