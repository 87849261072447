// src/components/PrivacyPolicy.tsx
import React, { useState, useEffect } from 'react';

const PrivacyPolicy: React.FC = () => {
  const [policy, setPolicy] = useState<string>('');

  useEffect(() => {
    fetch('/privacy.html')
      .then(response => response.text())
      .then(data => setPolicy(data))
      .catch(error => console.error('Error fetching privacy policy:', error));
  }, []);

  return (
    <section className="bg-light dark:bg-dark relative overflow-hidden flex items-center py-8 px-4 sm:px-6 lg:px-8" id="home">
    <div className="max-w-4xl mx-auto flex flex-col lg:flex-row items-center">
      <div
        className="bg-light dark:bg-dark text-gray-900 dark:text-gray-200"
        dangerouslySetInnerHTML={{ __html: policy }}
      />
      </div>
    </section>
  );
};

export default PrivacyPolicy;