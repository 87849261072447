// src/components/ProductLaunch.tsx
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  AlertTriangle,
  Award,
  BookOpen,
  Users,
  BrainCircuit,
  BarChart2,
  UsersRound,
  GraduationCap,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInitializeCases } from '../hooks/useInitializeCases';
import CaseViewer from './CaseViewer';
import {
  ConditionPerformanceChart,
  PerformanceComparisonChart,
  SubgroupChart,
} from './Charts';
import { ShinyButton, SidebarNav } from './Shared';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

// Text Content
const content = {
  hero: {
    title: "a2z-1: The First Multi-Disease AI Model for Abdomen-Pelvis CT",
    subtitle:
      "Unlike traditional single-disease AI solutions, a2z-1 is a groundbreaking generalist model capable of detecting 21 actionable conditions in abdomen-pelvis CT scans—from common findings like appendicitis to critical conditions like aortic aneurysms.",
    buttonText: "Partner With Us",
  },
  impact: {
    title: "Prototype Capabilities",
    description:
      "As a design partner, you'll get early access to explore these emerging capabilities:",
    points: [
      {
        title: "Experimental Analysis Pipeline",
        description:
          "Test our prototype pipeline that analyzes abdomen-pelvis CT scans across 21 findings. Help us validate and refine the model's analytical approach.",
        icon: BarChart2,
      },
      {
        title: 'Collaborative Development',
        description:
          'Work directly with our research team to shape feature development and identify new use cases based on your real-world needs.',
        icon: UsersRound,
      },
      {
        title: 'Early Performance Insights',
        description:
          'Get visibility into preliminary performance metrics and help establish meaningful benchmarks for clinical implementation.',
        icon: GraduationCap,
      },
    ],
  },
  howItWorks: {
    title: 'Why Multi-Disease Detection Matters',
    description: 'Our approach transforms abdomen-pelvis CT interpretation:',
    steps: [
      {
        title: 'Beyond Single-Disease AI',
        description:
          'While existing AI tools focus on one condition, a2z-1 simultaneously detects 21 actionable findings in abdomen-pelvis CT—from appendicitis and hepatic steatosis to unruptured aortic aneurysms.',
      },
      {
        title: 'Comprehensive Safety Net',
        description:
          'By analyzing multiple conditions at once, a2z-1 acts as an AI-driven safety net, ensuring no critical finding goes undetected in this challenging modality.',
      },
      {
        title: 'Validated Performance',
        description:
          'Validated across 14,000 scans from three health systems, achieving AUC scores of 0.855-0.970 for all 21 conditions.',
      }
    ],
  },
  validation: {
    title: 'Early Validation Results',
    points: [
      {
        title: 'Prototype Performance',
        description:
          'Initial testing shows promising results across 21 findings, with preliminary metrics ranging from 0.855 to 0.970. Help us validate these results in real-world settings.',
        icon: Award,
      },
      {
        title: 'Ongoing Research',
        description:
          'Active research partnerships are helping us understand model behavior across different clinical contexts and patient populations.',
        icon: AlertTriangle,
      },
      {
        title: 'Collaborative Refinement',
        description:
          'Work with our team to identify edge cases, refine detection criteria, and optimize for your specific needs.',
        icon: Users,
      },
    ],
  },
  takeNextStep: {
    title: "Join Our Design Partner Program",
    description:
      "We're looking for innovative radiology departments to help shape the future of AI in medical imaging. As a design partner, you'll get:",
    userTypes: [
      {
        title: 'Early Access & Integration',
        description:
          'Get first access to our technology and work with our team to integrate a2z-1 into your existing workflows for testing and validation.',
      },
      {
        title: 'Direct Product Influence',
        description:
          'Shape our development roadmap through regular feedback sessions and help us refine features based on real-world clinical needs.',
      },
      {
        title: 'Research Collaboration',
        description:
          `Participate in validation studies and contribute to scientific publications demonstrating a2z-1's performance across diverse clinical settings.`,
      },
    ],
    buttonText: "Apply for Partnership",
  },
  importantNotice: {
    title: 'Prototype Status Notice',
    description:
      'a2z-1 is a prototype tool in active development, intended for research and validation purposes only. It is not FDA-cleared and not for clinical use or diagnosis. Design partners will be involved in non-clinical testing and feature development only.',
  },
};

// Add this to the content object
const validationPages = [
  {
    title: 'Preliminary Results',
    description: 'Early performance data from our prototype testing across different settings.',
    icon: Award,
    path: '/validation/external'
  },
  {
    title: 'Error Analysis',
    description: 'Help us understand and improve model behavior in challenging cases.',
    icon: AlertTriangle,
    path: '/validation/miss-analysis'
  },
  {
    title: 'Research Directions',
    description: 'Explore ongoing investigations and future development priorities.',
    icon: Users,
    path: '/validation/subgroup'
  }
];

// Hero Section Component
const HeroSection = ({ openCalendly }: { openCalendly: () => void }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <section
      className="bg-light dark:bg-dark relative overflow-hidden flex items-center py-8 px-4 sm:px-6 lg:px-8"
      id="home"
    >
      <div className="relative z-10 max-w-4xl mx-auto flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="w-full lg:w-1/2 lg:pr-8">
          <motion.h1
            className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4 text-primary dark:text-accent text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {content.hero.title}
          </motion.h1>
          <motion.p
            className="text-lg sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            {content.hero.subtitle}
          </motion.p>

          {/* Button Container */}
          <div className="flex justify-start">
            <ShinyButton
              onClick={openCalendly}
              className="sm:mx-0 mx-auto mb-4 lg:mb-0"
            >
              {content.hero.buttonText}
            </ShinyButton>
          </div>
        </div>

        {/* Visual Content */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <motion.div
            className="relative w-full max-w-3xl mx-auto"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: isImageLoaded ? 1 : 0,
              scale: isImageLoaded ? 1 : 0.8,
            }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <img
              src="/playground_data/bbox_plots/EM9_pancreas_acute_pancreatitis.png"
              alt="AI-Generated Insight: Abdomen-Pelvis CT"
              className="rounded-lg shadow-2xl w-full object-contain-custom"
              onLoad={() => setIsImageLoaded(true)}
            />
            <motion.div
              className="absolute -bottom-4 -right-4 bg-white dark:bg-gray-800 p-3 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 1.2 }}
            >
              <p className="text-xs font-semibold text-gray-800 dark:text-gray-200">
                AI-Generated Insight: Abdomen-Pelvis CT
              </p>
              <p className="text-xs text-gray-600 dark:text-gray-400">
                Confidence: High
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Real-World Impact Section with CaseViewer
interface RealWorldImpactProps {
  cases: any; // Replace 'any' with actual type if available
  isLoading: boolean;
  error: any; // Replace 'any' with actual type if available
}

const RealWorldImpactSection: React.FC<RealWorldImpactProps> = ({
  cases,
  isLoading,
  error,
}) => {
  const impactPoints = content.impact.points;

  return (
    <section id="impact" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.impact.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-6 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {content.impact.description}
        </motion.p>
        <ul className="space-y-6">
          {impactPoints.map((item, index) => (
            <motion.li
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 + index * 0.2 }}
            >
              <item.icon className="mr-4 h-6 w-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
      {/* Full-Width CaseViewer with Max Width */}
      <div className="w-full flex justify-center px-4 sm:px-8">
        <div className="w-full max-w-3xl">
          <CaseViewer cases={cases} isLoading={isLoading} error={error} />
        </div>
      </div>
    </section>
  );
};

// How a2z-1 Works Section
const HowA2z1WorksSection = () => {
  const steps = content.howItWorks.steps;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <section id="how-it-works" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {content.howItWorks.title}
        </motion.h2>
        <motion.p
          className="text-md sm:text-xl mb-8 text-gray-700 dark:text-gray-300 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          {content.howItWorks.description}
        </motion.p>
        {/* Workflow Steps */}
        <motion.div
          className="flex flex-col md:flex-row md:space-x-8 space-y-8 md:space-y-0"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex-1 flex items-start"
              variants={itemVariants}
            >
              {/* Numbered Badge */}
              <div className="relative mr-4">
                <div className="flex items-center justify-center h-10 w-10 bg-primary text-white rounded-full text-lg font-bold">
                  {index + 1}
                </div>
              </div>
              {/* Step Details */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200">
                  {step.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {step.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// Scientific Validation Section with Tabbed Interface
const ScientificValidationSection = () => {
  const validationPoints = content.validation.points;
  const [activeTab, setActiveTab] = useState(0);

  // Define the tabs array with proper typing
  interface Tab {
    label: string;
    component: JSX.Element;
  }

  const tabs: Tab[] = [
    {
      label: 'Condition Performance',
      component: <ConditionPerformanceChart />,
    },
    {
      label: 'Performance Comparison',
      component: <PerformanceComparisonChart />,
    },
    {
      label: 'Subgroup Analysis',
      component: <SubgroupChart />
    },
  ];

  return (
    <section id="validation" className="bg-light dark:bg-dark">
      <div className="py-10 px-4 sm:px-8 max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl sm:text-4xl font-bold mb-6 text-gray-900 dark:text-gray-200 text-left"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {content.validation.title}
        </motion.h2>

        {/* Validation Page Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {validationPages.map((page, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <Link
                to={page.path}
                className="block h-full bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6"
              >
                <page.icon className="h-12 w-12 text-primary mb-4" />
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-200 mb-2">
                  {page.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {page.description}
                </p>
                <div className="mt-4 text-primary dark:text-accent font-semibold flex items-center">
                  Learn More
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        {/* Rest of the existing validation content */}
        <div className="space-y-8">
          {validationPoints.map((item, index) => (
            <motion.div
              key={index}
              className="flex items-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 * index }}
            >
              <item.icon className="mr-4 h-8 w-8 text-primary flex-shrink-0" />
              <div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">
                  {item.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {item.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Tabbed Interface */}
        <div className="mt-12">
          {/* Tab Headers */}
          <div className="flex border-b border-gray-300 dark:border-gray-700">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`py-2 px-4 -mb-px font-semibold text-sm transition-colors duration-300 focus:outline-none ${
                  activeTab === index
                    ? 'border-b-2 border-primary text-primary'
                    : 'text-gray-600 dark:text-gray-300 hover:text-primary'
                }`}
                onClick={() => setActiveTab(index)}
                aria-selected={activeTab === index}
                role="tab"
                aria-controls={`tabpanel-${index}`}
                id={`tab-${index}`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          {/* Tab Panels */}
          <div className="mt-4">
            {tabs.map((tab, index) => (
              <AnimatePresence key={index}>
                {activeTab === index && (
                  <motion.div
                    id={`tabpanel-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    {tab.component}
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

// Take the Next Step Section
const TakeNextStepSection = ({
  openCalendly,
}: {
  openCalendly: () => void;
}) => (
  <section id="take-the-next-step" className="bg-light dark:bg-dark">
    <div className="py-16 px-4 sm:px-8 max-w-4xl mx-auto text-left">
      <motion.h2
        className="text-4xl sm:text-5xl font-extrabold mb-6 text-gray-900 dark:text-gray-200"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        {content.takeNextStep.title}
      </motion.h2>
      <motion.p
        className="text-lg sm:text-2xl mb-12 text-gray-700 dark:text-gray-300"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {content.takeNextStep.description}
      </motion.p>
      {/* Upgraded "If you're a:" Section */}
      <div className="mb-8">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-300 mb-4">
          If you're a:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <Users className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
              {content.takeNextStep.userTypes[0].title}
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[0].description}
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <BrainCircuit className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
              {content.takeNextStep.userTypes[1].title}
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[1].description}
            </p>
          </div>
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <BookOpen className="h-8 w-8 text-primary mb-4" />
            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-200">
              {content.takeNextStep.userTypes[2].title}
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              {content.takeNextStep.userTypes[2].description}
            </p>
          </div>
        </div>
      </div>
      {/* Centered Button */}
      <motion.div
        className="flex justify-center mb-12"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <ShinyButton onClick={openCalendly}>
          {content.takeNextStep.buttonText}
        </ShinyButton>
      </motion.div>
    </div>
  </section>
);

// Main Product Launch Component
interface A2z1launchProps {
  openCalendly: () => void;
}

const A2z1launch: React.FC<A2z1launchProps> = ({ openCalendly }) => {
  const { cases, isLoading, error } = useInitializeCases({
    casesUrl: '/playground_data/cases.json',
    commentariesUrl: '/playground_data/commentaries.json',
    imagesPath: '/playground_data/bbox_plots',
  });

  // Define navItems to pass to SidebarNav
  const navItems = [
    { name: 'How It Works', to: 'how-it-works' },
    { name: 'Impact', to: 'impact' },
    { name: 'Validation', to: 'validation' },
    { name: 'Connect', to: 'take-the-next-step' },
  ];

  return (
    <div className="bg-light text-gray-900 dark:bg-dark dark:text-gray-300 min-h-screen transition-colors duration-300">
      <Helmet>
        <title>{content.hero.title}</title>
        <meta name="description" content={content.hero.subtitle} />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={content.hero.title} />
        <meta property="og:description" content={content.hero.subtitle} />
        <meta property="og:image" content="/og-image.png" />
        <meta
          property="og:url"
          content="https://a2zradiology.ai/news/a2z1-product-launch"
        />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={content.hero.title} />
        <meta name="twitter:description" content={content.hero.subtitle} />
        <meta name="twitter:image" content="/og-image.png" />

        {/* Canonical URL */}
        <link
          rel="canonical"
          href="https://a2zradiology.ai/news/a2z1-product-launch"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${content.hero.title}",
              "description": "${content.hero.subtitle}",
              "url": "https://a2zradiology.ai/news/a2z1-product-launch",
              "image": "/og-image.png",
              "publisher": {
                "@type": "Organization",
                "name": "a2z Radiology AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "/a2z-logo-square-500px.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      {/* SidebarNav */}
      <SidebarNav sections={navItems} />

      {/* Main Content Area with adjusted margin */}
      <div className="lg:ml-16">
        <Element name="home">
          <HeroSection openCalendly={openCalendly} />
        </Element>

        <Element name="how-it-works">
          <HowA2z1WorksSection />
        </Element>

        <Element name="impact">
          <RealWorldImpactSection
            cases={cases}
            isLoading={isLoading}
            error={error}
          />
        </Element>

        <Element name="validation">
          <ScientificValidationSection />
        </Element>

        <Element name="take-the-next-step">
          <TakeNextStepSection openCalendly={openCalendly} />
        </Element>

      </div>
    </div>
  );
};

export default A2z1launch;
