import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ShinyButton } from './Shared';

const A2ZSubgroupAnalysis: React.FC<{ openCalendly: () => void }> = ({ openCalendly }) => {
  return (
    <div className="min-h-screen bg-white dark:bg-dark text-gray-900 dark:text-gray-100">
      <Helmet>
        <title>a2z-1 Subgroup Analysis: Rigorous Validation Across Diverse Clinical Scenarios</title>
        <meta
          name="description"
          content="Explore the comprehensive subgroup analysis of a2z-1, our AI model for abdomen-pelvis CT imaging, demonstrating consistent high performance across diverse imaging protocols and patient groups."
        />
        <meta
          property="og:title"
          content="a2z-1 Subgroup Analysis: Rigorous Validation Across Diverse Clinical Scenarios"
        />
        <meta
          property="og:description"
          content="Explore the comprehensive subgroup analysis of a2z-1, our AI model for abdomen-pelvis CT imaging, demonstrating consistent high performance across diverse imaging protocols and patient groups."
        />
        <meta property="og:image" content="/auc_scores_by_subgroup_a2z_Radiology_AI.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="a2z-1 Subgroup Analysis: Rigorous Validation Across Diverse Clinical Scenarios"
        />
        <meta
          name="twitter:description"
          content="Explore the comprehensive subgroup analysis of a2z-1, our AI model for abdomen-pelvis CT imaging, demonstrating consistent high performance across diverse imaging protocols and patient groups."
        />
        <meta name="twitter:image" content="/auc_scores_by_subgroup_a2z_Radiology_AI.png" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": "a2z-1 Subgroup Analysis: Rigorous Validation Across Diverse Clinical Scenarios",
              "image": [
                "/auc_scores_by_subgroup_a2z_Radiology_AI.png"
              ],
              "datePublished": "2024-10-28T13:00:00-04:00",
              "dateModified": "2024-10-28T13:00:00-04:00",
              "author": [{
                "@type": "Organization",
                "name": "a2z Radiology AI",
                "url": "https://www.a2zradiology.ai"
              }],
              "publisher": {
                "@type": "Organization",
                "name": "a2z Radiology AI",
                "logo": {
                  "@type": "ImageObject",
                  "url": "/a2z-logo-square-500px.png"
                }
              },
              "description": "Explore the comprehensive subgroup analysis of a2z-1, our AI model for abdomen-pelvis CT imaging, demonstrating consistent high performance across diverse imaging protocols and patient groups."
            }
          `}
        </script>
      </Helmet>

      <main className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-bold mb-8 text-primary dark:text-accent"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          a2z-1 Subgroup Analysis: Rigorous Validation Across Diverse Clinical Scenarios
        </motion.h1>

        <motion.div
          className="flex items-center space-x-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {/* Author Logo */}
          <img
            src="/a2z-logo-square-500px.png"
            alt="a2z Radiology AI"
            className="w-10 h-10 rounded-full object-cover"
          />

          {/* Author Name, Date, and Image */}
          <div className="flex items-center space-x-4">
            <img
              src="/PranavRajpurkar.jpg"
              alt="Pranav Rajpurkar"
              className="w-12 h-12 rounded-full object-cover"
            />
            <img
              src="/SamirRajpurkar.jpg"
              alt="Samir Rajpurkar"
              className="w-12 h-12 rounded-full object-cover"
            />
            <div>
              <p className="text-lg text-gray-600 dark:text-gray-300">
                By{' '}
                <a
                  href="https://a2zradiology.ai"
                  className="text-primary dark:text-accent hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  a2z Radiology AI
                </a>{' '}
                on October 28, 2024
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                Connect with{' '}
                <a
                  href="https://www.linkedin.com/in/pranavrajpurkar/"
                  className="text-primary dark:text-accent hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pranav Rajpurkar
                </a>{' '}
                and{' '}
                <a
                  href="https://www.linkedin.com/in/samir-rajpurkar-79291396/"
                  className="text-primary dark:text-accent hover:underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Samir Rajpurkar
                </a>{' '}
                on LinkedIn
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="text-md sm:text-xl space-y-6 text-gray-800 dark:text-gray-200"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <p>
            At a2z Radiology AI, we believe that rigorous science is the cornerstone of innovation. Our commitment to scientific excellence drives us to set high standards in developing and validating our AI models. This commitment extends to ensuring the development of algorithms that perform consistently across diverse patient populations. In line with this philosophy, we've conducted an extensive subgroup analysis of a2z-1, our AI model designed to interpret abdomen-pelvis CT scans for 21 critical conditions.
          </p>

          <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg mb-8">
            <p className="font-bold text-lg mb-2">Design Partnership Opportunity</p>
            <p>
              As we continue to establish the value and clinical evidence of a2z-1, we invite healthcare institutions to join us in a design partnership. Together, we can further validate and refine the strongest tool for abdomen-pelvis CT interpretation.
            </p>
            <ShinyButton onClick={openCalendly} className="mt-4">
              Explore partnership opportunities
            </ShinyButton>
          </div>

          <h2 className="text-2xl font-bold mt-8 mb-4">Why Subgroup Analysis Matters</h2>
          <p>
            Healthcare is inherently diverse. Patients differ in age, sex, and physiological characteristics. Imaging protocols can vary based on clinical needs or equipment differences. Ignoring these variations can mask potential weaknesses in a diagnostic model. Therefore, we set out to answer crucial questions:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Does a2z-1 maintain high accuracy across different imaging protocols?</li>
            <li>Is the model's performance consistent across various age groups?</li>
            <li>Does the type of imaging equipment affect the model's accuracy?</li>
            <li>Are there differences in performance when scanning different body regions?</li>
          </ul>
          <p>
            By addressing these questions through rigorous external validation, we ensure that a2z-1 stands up to the highest scientific standards, providing reliable performance across diverse scenarios.
          </p>


          <h2 className="text-2xl font-bold mt-8 mb-4">Comprehensive Subgroup Analysis Results</h2>

          <p>
            Our rigorous subgroup analysis demonstrates the consistent performance of a2z-1 across various clinical scenarios and patient demographics. The following chart illustrates the AUC scores for different subgroups:
          </p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <img
              src="/auc_scores_by_subgroup_a2z_Radiology_AI.png"
              alt="AUC Scores by Subgroup for a2z-1"
              className="my-8 mx-auto rounded-lg shadow-lg"
            />
          </motion.div>
          <p>
            This visualization shows that a2z-1 maintains high AUC scores across various subgroups, including different imaging protocols, age groups, sexes, imaging equipment, and body regions scanned.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">High Accuracy Maintained Across All Imaging Protocols</h2>
          <p>
            Variations in imaging protocols, such as the use of contrast agents, can influence image characteristics and affect diagnostic accuracy. Our analysis shows that a2z-1 delivers consistent high accuracy across different imaging protocols. For non-contrast scans, the model achieved an AUC of 0.924 (95% CI: 0.892–0.952). Similar high performance was observed for scans with IV and oral contrast (AUC 0.919, 95% CI: 0.871–0.957), IV contrast only (AUC 0.910, 95% CI: 0.841–0.963), and oral contrast only (AUC 0.905, 95% CI: 0.798–0.981).
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Strong Performance Across All Age Groups Validated Externally</h2>
          <p>
            Our evaluation of a2z-1 across different age groups, using data from two external health systems, demonstrates robust performance from pediatric to geriatric patients. The model maintains high AUC scores across all age brackets, with the highest performance observed in the 45-59 age group (AUC 0.920, 95% CI: 0.865–0.958). Notably, even for the under-18 group, the model achieved an AUC of 0.906 (95% CI: 0.829–0.976), and for those 75 and above, an AUC of 0.895 (95% CI: 0.843–0.941). These results underscore the model's generalizability across the entire age spectrum.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Minimal Variation in Performance Between Sexes</h2>
          <p>
            Our external validation shows minimal difference in AUC scores between female and male patients. For female patients, the model achieved an AUC of 0.917 (95% CI: 0.882–0.947), while for male patients, it reached an AUC of 0.925 (95% CI: 0.892–0.953).
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">High Accuracy Across Different Imaging Equipment</h2>
          <p>
            To ensure a2z-1 performs consistently across different systems, we tested the model on scans from various manufacturers not involved in the development phase. The results show high AUC scores for major manufacturers: Canon (AUC 0.931, 95% CI: 0.888–0.967), GE (AUC 0.921, 95% CI: 0.891–0.947), and Siemens (AUC 0.927, 95% CI: 0.883–0.962). There was a decrease in performance with less common equipment (AUC 0.845, 95% CI: 0.709–0.932), which warrants further investigation. Overall, a2z-1 maintains robust accuracy across major scanner types, demonstrating its adaptability to different imaging technologies.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Consistency Despite Variations in Body Regions Scanned</h2>
          <p>
            Our analysis, conducted on external datasets, reveals that a2z-1 maintains high performance across different body regions scanned. For abdomen/pelvis scans, the model achieved an AUC of 0.924 (95% CI: 0.896–0.947). Similar high performance was observed for abdomen-only scans (AUC 0.924, 95% CI: 0.863–0.966) and pelvis-only scans (AUC 0.913, 95% CI: 0.816–0.984). There's a noticeable difference in performance for chest/abdomen/pelvis scans (AUC 0.875, 95% CI: 0.770–0.968), which may be due to the increased complexity of these scans.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Reliability Across Different Image Resolutions (Slice Thickness)</h2>
          <p>
            Our evaluation across varying slice thicknesses further validates a2z-1's robustness. For slice thickness less than 3mm, the model achieved an AUC of 0.920 (95% CI: 0.870–0.958). The highest performance was observed in the 3-5mm range with an AUC of 0.941 (95% CI: 0.887–0.977), while for slice thickness 5mm and above, the AUC was 0.922 (95% CI: 0.894–0.948). This consistency across resolutions reinforces the model's reliability in various imaging settings.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">True External Validation Sets a High Scientific Standard</h2>
          <p>
            Our analysis is conducted on data from two independent health systems, with no patient overlap from the development datasets, providing a comprehensive external validation. This approach, while challenging due to data access limitations and the potential for performance variations in new datasets, offers valuable insights into the model's adaptability. We believe this thorough evaluation helps demonstrate the model's potential for generalizability and reliability across different healthcare settings.
          </p>

          <h2 className="text-2xl font-bold mt-8 mb-4">Conclusion</h2>
          <p>
            Our comprehensive subgroup analysis, grounded in rigorous science and true external validation, demonstrates that a2z-1 maintains consistent high performance across diverse imaging protocols and patient groups. This analysis underscores our commitment to fairness, equity, and the development of unbiased algorithms in healthcare AI.
          </p>

          <p className="font-semibold text-lg mt-6">
            Key Takeaways:
          </p>
          <ul className="list-disc pl-6 space-y-2 mb-8">
            <li>Consistent performance across imaging protocols</li>
            <li>Strong accuracy for all age groups</li>
            <li>Minimal variation between sexes</li>
            <li>High performance across different imaging equipment</li>
            <li>Reliability across various body regions scanned</li>
          </ul>

        <div className="bg-blue-100 dark:bg-blue-900 p-4 rounded-lg mb-8">
          <p className="font-bold text-lg mb-2">Design Partnership Opportunity</p>
          <p>
            As we continue to establish the value and clinical evidence of a2z-1, we invite healthcare institutions to join us in a design partnership. Together, we can further validate and refine the strongest tool for abdomen-pelvis CT interpretation.
          </p>
          <ShinyButton onClick={openCalendly} className="mt-4">
            Explore partnership opportunities
          </ShinyButton>
        </div>
        </motion.div>

        <motion.div
          className="mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.8 }}
        >
          <Link to="/" className="text-primary dark:text-accent hover:underline">
            &larr; Back to Home
          </Link>
        </motion.div>
      </main>
    </div>
  );
};

export default A2ZSubgroupAnalysis;
