import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom'; // Imported Link from react-router-dom
import { Link as ScrollLink } from 'react-scroll'; // Import ScrollLink

// Navbar Component
interface NavbarProps {
  openCalendly: () => void;
}

export const Navbar: React.FC<NavbarProps> = ({ openCalendly }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { to: "/", text: "The a2z-1 Launch" },
    { to: "/story", text: "The a2z Story" },
    {
      text: "Validation Studies",
      children: [
        { to: "/validation", text: "External Validation" },
        { to: "/subgroup-analysis", text: "Subgroup Analysis" },
        { to: "/miss-analysis", text: "Radiologist Misses Analysis" },
      ]
    }
  ];

  const renderNavItem = (item: { to?: string; text: string; children?: Array<{ to: string; text: string }> }, isMobile: boolean) => {
    if (item.children) {
      return (
        <div key={item.text} className={`relative group ${isMobile ? "mb-4" : ""}`}>
          <div className="text-gray-700 dark:text-gray-300 hover:text-gray-500 flex items-center gap-1 cursor-pointer">
            {item.text}
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div className={`
            ${isMobile
              ? "mt-2 ml-4 space-y-2"
              : "absolute left-0 mt-2 w-56 bg-white dark:bg-dark shadow-lg rounded-md py-2 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200"}
            z-50
          `}>
            {item.children.map((child) => (
              <Link
                key={child.to}
                to={child.to}
                className={`
                  ${isMobile
                    ? "block"
                    : "block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800"}
                  text-gray-700 dark:text-gray-300 hover:text-gray-500
                `}
                onClick={() => setIsOpen(false)}
              >
                {child.text}
              </Link>
            ))}
          </div>
        </div>
      );
    }

    return item.to ? (
      <Link
        key={item.to}
        to={item.to}
        className={`text-gray-700 dark:text-gray-300 hover:text-gray-500 ${
          isMobile ? "block mb-2" : ""
        }`}
        onClick={() => setIsOpen(false)}
      >
        {item.text}
      </Link>
    ) : null;
  };

  const renderNavItems = (isMobile: boolean) => (
    <>
      {navItems.map((item) => renderNavItem(item, isMobile))}
      <ShinyButton
        onClick={openCalendly}
        className={isMobile ? "w-full text-left" : ""}
      >
        Explore a2z-1
      </ShinyButton>
    </>
  );

  return (
    <nav className="bg-light dark:bg-dark py-4 px-6 sticky top-0 w-full z-50 shadow-md">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Link to="/">
            <img src="/a2z-logo-linear.png" alt="a2z Logo" className="w-48 h-auto" />
          </Link>
        </div>
        <div className="flex items-center lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-700 dark:text-gray-300 focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex items-center space-x-4">
          {renderNavItems(false)}
        </div>
      </div>

      {isOpen && (
        <div className="lg:hidden mt-4">
          {renderNavItems(true)}
        </div>
      )}
    </nav>
  );
};

// Footer Component
interface FooterProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
  openCalendly: () => void;
}

export const Footer: React.FC<FooterProps> = ({ isDarkMode, toggleTheme, openCalendly }) => {
  const footerLinks = [
    { text: 'Home', href: '/' },
    { text: 'a2z-1', href: '/' },
    { text: 'The a2z Story', href: '/story' },
    {
      text: 'Validation Studies',
      children: [
        { text: 'External Validation', href: '/validation' },
        { text: 'Subgroup Analysis', href: '/subgroup-analysis' },
        { text: 'Radiologist Misses Analysis', href: '/miss-analysis' },
      ]
    },
    { text: 'Privacy Policy', href: '/privacy-policy' },
  ];

  const renderFooterSection = (title: string, links: typeof footerLinks, isQuickLinks: boolean) => (
    <div className="flex flex-col items-start">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ul className="space-y-2">
        {links.map((link, index) => (
          <li key={index}>
            {link.children ? (
              <>
                <span className="font-medium text-gray-700 dark:text-gray-300">{link.text}</span>
                <ul className="ml-4 mt-2 space-y-2">
                  {link.children.map((child, childIndex) => (
                    <li key={childIndex}>
                      <Link
                        to={child.href}
                        className="hover:text-gray-500 transition-colors duration-200"
                      >
                        {child.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              isQuickLinks ? (
                <Link
                  to={link.href}
                  className="hover:text-gray-500 transition-colors duration-200"
                >
                  {link.text}
                </Link>
              ) : (
                <a
                  href={link.href}
                  className="hover:text-gray-500 transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.text}
                </a>
              )
            )}
          </li>
        ))}
        {!isQuickLinks && (
          <li>
            <ShinyButton onClick={openCalendly} className="mt-2">
              Explore a2z-1
            </ShinyButton>
          </li>
        )}
      </ul>
    </div>
  );

  return (
    <footer className="bg-light text-gray-600 py-8 px-4 dark:bg-dark dark:text-gray-400 border-t border-gray-300 dark:border-gray-700">
      <motion.div
        className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex flex-col items-start">
          <img src="/a2z-logo-linear.png" alt="a2z Logo" className="w-40 sm:w-48 h-auto mb-4" />
          <p className="text-left text-gray-600">Boston, MA</p>
        </div>

        {renderFooterSection("Quick Links", footerLinks.slice(0, 3), true)}
        {renderFooterSection("Resources", footerLinks.slice(3), false)}

        {/* Important Notice
        <div className="flex flex-col items-start">
          <p className="text-sm text-gray-600 dark:text-gray-400">
          a2z-1 is intended for research, educational, and analytical purposes. It is not approved for clinical or diagnostic use by the FDA in the United States. Availability and any clinical application outside the USA are subject to local regulations and guidelines.
          </p>
        </div> */}
      </motion.div>

      <motion.div
        className="mt-8 border-t pt-4 flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <p className="text-left text-gray-600">&copy; 2024 a2z Radiology AI. All rights reserved.</p>
        <button onClick={toggleTheme} className="mt-4 md:mt-0 text-gray-600 dark:text-gray-400 focus:outline-none">
          {isDarkMode ? <Sun className="h-6 w-6" /> : <Moon className="h-6 w-6" />}
        </button>
      </motion.div>
    </footer>
  );
};

// SidebarNav Component
interface SidebarNavProps {
  sections: { name: string; to: string }[];
}

export const SidebarNav: React.FC<SidebarNavProps> = ({ sections }) => {
  const [isVisible, setIsVisible] = useState(false);
  React.useEffect(() => {
    const handleScroll = () => {
      const homeSection = document.getElementById('home');
      if (homeSection) {
        const homeSectionBottom = homeSection.offsetTop + homeSection.offsetHeight;
        setIsVisible(window.scrollY > homeSectionBottom - 100);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  return (
    <nav className="hidden lg:block fixed left-0 top-1/4 transform -translate-y-1/4 z-50 w-[10.75rem] ml-4 transition-opacity duration-300 ease-in-out">
      <div className="relative overflow-hidden rounded-md">
        <div className="bg-base/80 absolute inset-0 z-0"></div>
        <ul className="flex flex-col py-4 px-3 relative z-10">
          {sections.map(({ name, to }) => (
            <li key={to} className="mb-2 last:mb-0">
              <ScrollLink
                to={to}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="text-sm leading-[1.2] transition-colors block py-1 px-2 rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                activeClass="font-bold text-dark bg-light dark:bg-secondary dark:text-white"
              >
                {name}
              </ScrollLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

// ScrollProgressBar Component
export const ScrollProgressBar: React.FC = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      const currentScroll = window.scrollY; // Replaced window.pageYOffset with window.scrollY
      const scrollPercentage = (currentScroll / totalScroll) * 100;
      setScrollProgress(scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-1 bg-primary dark:bg-secondary z-50"
      style={{ scaleX: scrollProgress / 100 }}
    />
  );
};

// ShinyButton Component
interface ShinyButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

export const ShinyButton: React.FC<ShinyButtonProps> = ({ onClick, children, className = '' }) => (
  <motion.button
    className={`relative overflow-hidden bg-primary text-white font-bold py-3 px-6 text-lg transition-all flex items-center shine-effect ${className}`}
    onClick={onClick}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="flex items-center relative z-10">
      {children}
      <ArrowRight className="ml-2 h-5 w-5" />
    </div>
  </motion.button>
);
