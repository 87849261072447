// src/components/CaseViewer.tsx
import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  memo,
} from 'react';
import {
  FaUserMd,
  FaEye,
  FaCheckCircle,
  FaTimes,
} from 'react-icons/fa';

/* Define the Case interface */
export interface Case {
  name: string;
  findings: Record<string, number | string>;
  images: Record<string, string>;
  commentary: string;
}

/* Utility Functions */

const getSortedRelevantConditions = (
  findings: Record<string, number | string>
) => {
  const classOrder: { [key: string]: number } = { likely: 0, possible: 1 };

  return Object.entries(findings)
    .filter(
      ([key, value]) =>
        key.startsWith('class_') && (value === 'likely' || value === 'possible')
    )
    .sort(([keyA, valueA], [keyB, valueB]) => {
      const classA = valueA as string;
      const classB = valueB as string;
      const classCompare = classOrder[classA] - classOrder[classB];
      if (classCompare !== 0) return classCompare;

      const probKeyA = keyA.replace('class_', '');
      const probKeyB = keyB.replace('class_', '');
      const probA = findings[probKeyA] as number;
      const probB = findings[probKeyB] as number;
      return probB - probA;
    })
    .map(([key]) => key.replace('class_', ''));
};

const getImage = (
  caseData: Case,
  selectedCondition: string | null
): string | null => {
  if (selectedCondition && caseData.images[selectedCondition]) {
    return caseData.images[selectedCondition];
  }
  const imageValues = Object.values(caseData.images);
  return imageValues.length > 0 ? imageValues[0] : null;
};

/* Custom Hook */

function useCaseViewer<T extends Record<string, any>>(cases: T) {
  const [currentCase, setCurrentCase] = useState<keyof T>(
    () => Object.keys(cases)[0] as keyof T
  );
  const [selectedConditions, setSelectedConditions] = useState<
    Record<keyof T, string | null>
  >({} as Record<keyof T, string | null>);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);

  const selectTopFinding = useCallback(
    (caseKey: keyof T) => {
      const caseData = cases[caseKey];
      if (caseData && caseData.findings) {
        const sortedFindings = getSortedRelevantConditions(caseData.findings);
        if (sortedFindings.length > 0) {
          setSelectedConditions((prev) => ({
            ...prev,
            [caseKey]: sortedFindings[0],
          }));
        } else {
          setSelectedConditions((prev) => ({
            ...prev,
            [caseKey]: null,
          }));
        }
      }
    },
    [cases]
  );

  useEffect(() => {
    if (cases && Object.keys(cases).length > 0) {
      const initialCase = Object.keys(cases)[0] as keyof T;
      setCurrentCase(initialCase);
      Object.keys(cases).forEach((caseKey) => {
        const key = caseKey as keyof T;
        selectTopFinding(key);
      });
    }
  }, [cases, selectTopFinding]);

  const handleConditionClick = (condition: string, caseKey: keyof T) => {
    setSelectedConditions((prev) => ({ ...prev, [caseKey]: condition }));
  };

  useEffect(() => {
    const loadImage = async () => {
      if (currentCase && cases[currentCase]) {
        try {
          setImageError(null);
          const image = getImage(
            cases[currentCase],
            selectedConditions[currentCase]
          );
          setCurrentImage(image);
          if (!image) {
            setImageError('No Key Image');
          }
        } catch (error) {
          setCurrentImage(null);
          setImageError('Failed to load image. Please try again later.');
        }
      }
    };
    loadImage();
  }, [currentCase, selectedConditions, cases]);

  return {
    currentCase,
    setCurrentCase,
    selectedConditions,
    currentImage,
    imageError,
    handleConditionClick,
  };
}

/* Main Component */

interface CaseViewerProps {
  cases: Record<string, Case>;
  isLoading: boolean;
  error: string | null;
}

const CaseViewer: FC<CaseViewerProps> = ({
  cases,
  isLoading,
  error,
}) => {
  const {
    currentCase,
    setCurrentCase,
    selectedConditions,
    currentImage,
    imageError,
    handleConditionClick,
  } = useCaseViewer(cases);

  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (currentImage === null && !imageError) {
      setIsTransitioning(true);
    } else {
      setIsTransitioning(false);
    }
  }, [currentCase, currentImage, imageError]);


  if (isLoading) {
    return <div className="text-center p-4 dark:text-gray-300">Loading cases...</div>;
  }

  if (error) {
    return (
      <div className="text-red-500 p-4 dark:text-red-300">Error: {error}</div>
    );
  }

  if (!cases || Object.keys(cases).length === 0) {
    return <div className="p-4 dark:text-gray-300">No cases available.</div>;
  }

  const currentCaseData = cases[currentCase];

  if (!currentCaseData) {
    return (
      <div className="text-red-500 p-4 dark:text-red-300">
        Error: Current case data is undefined.
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-hidden items-center">
      {/* Sticky Navigation */}
      <div className="sticky top-0 w-full z-10 bg-white dark:bg-gray-900">
        <CaseNavigation
          cases={cases}
          currentCase={currentCase as string}
          setCurrentCase={(caseKey: string) =>
            setCurrentCase(caseKey as keyof typeof cases)
          }
        />
      </div>

      {/* Content */}
      <div className="flex-1 overflow-hidden w-full">
        <div className="h-full overflow-auto p-6">
          <div
            className="flex justify-center"
          >
            <CaseDisplay
              caseData={currentCaseData}
              currentImage={currentImage}
              imageError={imageError}
              onConditionClick={(condition) =>
                handleConditionClick(
                  condition,
                  currentCase as keyof typeof cases
                )
              }
              selectedCondition={
                selectedConditions[currentCase as keyof typeof cases]
              }
              isLoading={isTransitioning}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/* Sub-components */

/* CaseNavigation Component */

interface CaseNavigationProps {
  cases: Record<string, Case>;
  currentCase: string;
  setCurrentCase: (caseKey: string) => void;
}

const CaseNavigation: FC<CaseNavigationProps> = ({
  cases,
  currentCase,
  setCurrentCase,
}) => {
  const getFindings = (caseData: Case) => {
    const sortedConditions = getSortedRelevantConditions(caseData.findings);
    const likelyFindings = sortedConditions.filter(
      (condition) => caseData.findings[`class_${condition}`] === 'likely'
    );
    const possibleFindings = sortedConditions.filter(
      (condition) => caseData.findings[`class_${condition}`] === 'possible'
    );
    const mostImportantFinding = sortedConditions[0] || null;
    return {
      likelyCount: likelyFindings.length,
      possibleCount: possibleFindings.length,
      mostImportantFinding,
    };
  };

  return (
    <div className="py-2 px-4 bg-white dark:bg-gray-900 shadow-md border-b border-gray-200 dark:border-gray-700">
      <div className="flex items-center justify-between">
        <div className="flex-1 overflow-hidden">
          <div
            className="flex items-center space-x-2 overflow-x-auto hide-scrollbar"
            role="tablist"
          >
            {Object.entries(cases).map(([caseKey, caseData]) => {
              const { likelyCount, possibleCount, mostImportantFinding } =
                getFindings(caseData);
              const isActive = currentCase === caseKey;

              return (
                <button
                  key={caseKey}
                  role="tab"
                  aria-selected={isActive}
                  aria-controls={`panel-${caseKey}`}
                  id={`tab-${caseKey}`}
                  className={`flex-shrink-0 px-4 py-2 font-medium transition-colors duration-300 border-b-2 focus:outline-none focus:border-b-2 focus:border-primary ${
                    isActive
                      ? 'border-primary text-primary'
                      : 'border-transparent text-gray-700 hover:text-primary dark:text-gray-300 dark:hover:text-accent'
                  } rounded-md`}
                  onClick={() => setCurrentCase(caseKey)}
                >
                  <div className="flex flex-col items-start">
                    <span>{caseData.name}</span>
                    <div className="flex items-center mt-1 space-x-2 text-xs">
                      <span
                        className={`font-semibold ${
                          likelyCount > 0 || possibleCount > 0
                            ? 'text-primary'
                            : 'text-gray-500 dark:text-gray-400'
                        }`}
                      >
                        {mostImportantFinding || 'No finding'}
                      </span>
                      <div className="flex items-center space-x-1">
                        {(likelyCount > 0 || possibleCount > 0) && (
                          <div
                            className="flex items-center text-accent"
                            title={`${likelyCount + possibleCount} findings`}
                          >
                            <FaCheckCircle className="mr-1" />
                            <span>{likelyCount + possibleCount}</span>
                          </div>
                        )}
                        {likelyCount === 0 && possibleCount === 0 && (
                          <div
                            className="flex items-center text-gray-500 dark:text-gray-400"
                            title="No findings"
                          >
                            <FaTimes className="mr-1" />
                            <span>0</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

/* A2ZOutput Component */

interface A2ZOutputProps {
  findings: Record<string, number | string>;
  onConditionClick: (condition: string) => void;
  selectedCondition: string | null;
}

const A2ZOutput: FC<A2ZOutputProps> = memo(
  ({ findings, onConditionClick, selectedCondition }) => {
    const getCategory = (condition: string): string => {
      const classKey = condition.startsWith('class_')
        ? condition
        : `class_${condition}`;
      const classValue = findings[classKey];

      if (typeof classValue === 'string') {
        if (classValue === 'likely') return 'Detected';
        if (classValue === 'possible') return 'Suspected';
        return 'Not Found';
      }
      return 'Not Found';
    };

    const sortedFindings = Object.entries(findings)
      .filter(([key]) => !key.startsWith('class_'))
      .map(([condition, value]) => ({
        condition,
        value,
        category: getCategory(condition),
      }))
      .sort((a, b) => {
        // Prioritize Detected, then Suspected, then Not Found
        const categoryOrder: Record<string, number> = { Detected: 0, Suspected: 1, 'Not Found': 2 };
        const categoryCompare =
          categoryOrder[a.category] - categoryOrder[b.category];
        if (categoryCompare !== 0) return categoryCompare;
        // Sort by probability value if categories are the same
        if (typeof a.value === 'number' && typeof b.value === 'number') {
          return b.value - a.value;
        }
        return 0;
      });

    const renderFinding = (
      condition: string,
      category: string,
      isSelected: boolean
    ) => {
      const [organ, conditionName] = condition.split('/');
      const formattedCondition = conditionName.replace(/_/g, ' ');

      const categoryClasses: Record<string, string> = {
        Detected: 'bg-primary text-white',
        Suspected: 'bg-secondary text-white',
      };

      return (
        <div
          key={condition}
          className={`cursor-pointer ${
            isSelected ? 'shine-effect' : ''
          } transition-all duration-200 ease-in-out relative ${
            categoryClasses[category] || ''
          } rounded-md focus:outline-none focus:border-b-2 focus:border-primary`}
          onClick={() => onConditionClick(condition)}
          role="button"
          aria-pressed={isSelected}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onConditionClick(condition);
            }
          }}
        >
          {(category === 'Detected' || category === 'Suspected') && (
            <div className="absolute left-0 top-0 h-full w-1 bg-accent rounded-l-md"></div>
          )}
          <div className="flex-grow flex items-center justify-between py-2 px-4 relative z-10">
            <div className="flex items-center">
              {isSelected && <FaEye className="mr-2 text-accent" />}
              <span
                className={`font-medium truncate ${
                  isSelected ? 'font-bold' : ''
                }`}
              >
                {formattedCondition}
              </span>
            </div>
            {isSelected && (
              <span
                className="text-primary max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"
                title={organ.replace(/_/g, ' ')}
              >
                {organ.replace(/_/g, ' ')}
              </span>
            )}
          </div>
        </div>
      );
    };

    const detectedAndSuspected = sortedFindings.filter(
      (item) => item.category !== 'Not Found'
    );
    const notFound = sortedFindings
      .filter((item) => item.category === 'Not Found')
      .map((item) => {
        const [, conditionName] = item.condition.split('/');
        return conditionName.replace(/_/g, ' ');
      });

    return (
      <div className="space-y-4">
        {detectedAndSuspected.length > 0 &&
          ['Detected', 'Suspected'].map((category) => (
            <div key={category}>
              {detectedAndSuspected.some(
                (item) => item.category === category
              ) && (
                <>
                  <div className="text-sm font-medium mb-2">{category}</div>
                  <div className="space-y-2">
                    {detectedAndSuspected
                      .filter((item) => item.category === category)
                      .map((item) =>
                        renderFinding(
                          item.condition,
                          item.category,
                          item.condition === selectedCondition
                        )
                      )}
                  </div>
                </>
              )}
            </div>
          ))}

        {notFound.length > 0 && (
          <div>
            <div className="text-sm font-medium mb-2">Not Found</div>
            <div className="text-sm text-gray-800 dark:text-gray-200">
              {notFound.join(', ')}
            </div>
          </div>
        )}
      </div>
    );
  }
);

/* CaseDisplay Component */

interface CaseDisplayProps {
  caseData: {
    name: string;
    findings: Record<string, number | string>;
    commentary: string;
    images: Record<string, string>;
  };
  currentImage: string | null;
  imageError: string | null;
  onConditionClick: (condition: string) => void;
  selectedCondition: string | null;
  isLoading: boolean;
}

const CaseDisplay: FC<CaseDisplayProps> = memo(
  ({
    caseData,
    currentImage,
    imageError,
    onConditionClick,
    selectedCondition,
    isLoading,
  }) => {
    return (
      <div className="w-full max-w-4xl mx-auto">
        <div className="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8 text-gray-800 dark:text-gray-300">
          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center items-center">
            <div className="w-full">
              {isLoading ? (
                <div className="w-full h-80 bg-gray-200 dark:bg-gray-800 animate-pulse rounded-lg"></div>
              ) : currentImage ? (
                <img
                  src={currentImage}
                  alt={`CT Scan for ${caseData.name}`}
                  className="w-full h-auto object-contain rounded-lg shadow-2xl transition-transform duration-300 transform hover:scale-105 focus:outline-none focus:border-b-2 focus:border-primary"
                />
              ) : (
                <div className="h-80 bg-black dark:bg-gray-900 flex items-center justify-center text-white rounded-lg">
                  {imageError || 'No image available'}
                </div>
              )}
            </div>
          </div>

          {/* Predictions Section */}
          <div className="w-full md:w-1/2">
            <div className="border-2 border-secondary rounded-lg overflow-hidden shadow-lg">
              <div className="py-3 px-4 flex items-center bg-secondary text-accent">
                <img
                  src="/a2z-1-logo.png"
                  alt="a2z-1 Logo"
                  className="w-12 h-auto"
                />
                <h3 className="ml-2 text-lg font-semibold">Predictions</h3>
              </div>
              <div className="p-6 bg-white dark:bg-gray-800 transition-colors duration-300">
                {isLoading ? (
                  <div className="space-y-4 animate-pulse">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4"></div>
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full"></div>
                  </div>
                ) : (
                  <A2ZOutput
                    findings={caseData.findings}
                    onConditionClick={onConditionClick}
                    selectedCondition={selectedCondition}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Expert Commentary Section */}
        <div className="mt-8">
          <ExpertCommentary commentary={caseData.commentary} />
        </div>
      </div>
    );
  }
);

/* ExpertCommentary Component */

interface ExpertCommentaryProps {
  commentary: string;
}

const ExpertCommentary: FC<ExpertCommentaryProps> = memo(({ commentary }) => {
  return (
    <div className="border rounded-lg p-6 bg-white dark:bg-gray-800 border-primary dark:border-gray-700 focus:outline-none focus:border-b-2 focus:border-primary">
      <div className="flex items-center mb-4">
        <FaUserMd className="mr-2 text-xl text-primary dark:text-accent" />
        <h3 className="text-lg font-semibold text-primary dark:text-accent">
          Expert Commentary
        </h3>
      </div>
      <p className="text-sm leading-relaxed whitespace-pre-wrap text-gray-800 dark:text-gray-200">
        {commentary || 'No commentary available.'}
      </p>
    </div>
  );
});

export default CaseViewer;