import React, { useState, useEffect, useRef } from 'react';
import Papa from 'papaparse';
import {
  Radar,
  RadarChart,
  RadialBar,
  RadialBarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ArrowLeft, ArrowRight } from 'lucide-react';

// Custom hook for fetching CSV data
const useFetchCSVData = (filePaths: string[]) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (data.length === 0) {
        try {
          const fetchPromises = filePaths.map((filePath) =>
            fetch(filePath).then((response) => response.text())
          );

          const csvTexts = await Promise.all(fetchPromises);

          const parsedData = csvTexts.map((csvText) =>
            Papa.parse(csvText, { header: true }).data
          );

          if (isMounted) {
            setData(parsedData);
          }
        } catch (err) {
          if (isMounted) {
            setError(err as Error);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [filePaths, data.length]);

  return { data, error };
};

// Enhanced toTitleCase function with special case handling
const toTitleCase = (str: string | undefined | null): string => {
  if (!str) return 'Unknown';
  // Replace underscores with spaces and convert to lowercase for mapping
  const formattedStr = str.replace(/_/g, ' ').toLowerCase();

  return formattedStr
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Interface declarations
interface SubCategory {
  name: string;
  auc: number;
}

interface CategoryData {
  category: string;
  subCategories: SubCategory[];
}

const ConditionPerformanceChart: React.FC = () => {
  const filePaths = ['/performance/AUC_external.csv'];
  const { data: csvData, error } = useFetchCSVData(filePaths);
  const [data, setData] = useState<any[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [maxLabelWidth, setMaxLabelWidth] = useState(0);

  const colors = [
    '#4361ee',
    '#4895ef',
    '#3f37c9',
    '#560bad',
    '#b5179e',
    '#f72585',
    '#4cc9f0',
  ];

  useEffect(() => {
    if (csvData.length === 1) {
      const parsedData = csvData[0].map((entry: any) => {
        const pathologyParts = entry.Pathology ? entry.Pathology.split('/') : [];
        let displayPathology = pathologyParts[1] || pathologyParts[0] || 'Unknown';
        displayPathology = toTitleCase(displayPathology);

        // Special case for retroperitoneal hemorrhage
        let organ = toTitleCase(entry.Organ) || 'Unknown';
        if (entry.Pathology.toLowerCase() === 'peritoneum/retroperitoneal_hemorrhage') {
          organ = 'Retroperitoneum';
        }

        return {
          Pathology: displayPathology,
          AUC: parseFloat(entry.AUC) || 0,
          Organ: organ,
        };
      });
      // Sort the data alphabetically by Pathology
      parsedData.sort((a: { Pathology: string; }, b: { Pathology: string; }) => a.Pathology.localeCompare(b.Pathology));

      setData(parsedData);
    }
  }, [csvData]);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (context) {
        context.font = '14px sans-serif';
        const widths = data.map((entry) => context.measureText(entry.Pathology).width);
        const maxWidth = Math.max(...widths);
        setMaxLabelWidth(maxWidth);
      }
    }
  }, [data]);

  const renderLinearChart = () => {
    const margin = {
      top: dimensions.height * 0.05,
      right: dimensions.width * 0.15,
      bottom: dimensions.height * 0.2,
      left: maxLabelWidth + 20,
    };
    const chartWidth = dimensions.width - margin.left - margin.right;
    const chartHeight = dimensions.height - margin.top - margin.bottom;
    const barHeight = (chartHeight / data.length) * 0.7;
    const scale = (value: number) => ((value - 0.5) / (1 - 0.5)) * chartWidth;

    // Create a map of unique organs to colors
    const organColors = new Map();
    data.forEach((entry) => {
      if (!organColors.has(entry.Organ)) {
        organColors.set(entry.Organ, colors[organColors.size % colors.length]);
      }
    });

    return (
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform={`translate(${margin.left},${margin.top})`}>
          <line
            x1={0}
            y1={chartHeight}
            x2={chartWidth}
            y2={chartHeight}
            stroke="#718096"
            strokeWidth={1}
          />
          {[0.5, 0.6, 0.7, 0.8, 0.9, 1].map((tick) => (
            <g key={tick} transform={`translate(${scale(tick)},${chartHeight})`}>
              <line y2={6} stroke="#718096" />
              <text y={20} textAnchor="middle" fill="currentColor" fontSize={12}>
                {tick.toFixed(1)}
              </text>
            </g>
          ))}
          <text
            x={chartWidth / 2}
            y={chartHeight + margin.bottom * 0.3}
            textAnchor="middle"
            fill="currentColor"
            fontSize={14}
          >
            AUC Value
          </text>

          {data.map((entry, index) => {
            const y =
              index * (chartHeight / data.length) +
              (chartHeight / data.length - barHeight) / 2;
            const x = scale(0.5);
            const barWidth = scale(entry.AUC) - x;
            const barColor = organColors.get(entry.Organ);

            return (
              <g
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                cursor="pointer"
              >
                <rect
                  x={x}
                  y={y}
                  width={barWidth}
                  height={barHeight}
                  fill={barColor}
                  className={`transition-colors duration-300 ease-in-out ${
                    hoveredIndex === index ? 'opacity-80 dark:opacity-100' : 'opacity-70 dark:opacity-80'
                  }`}
                />
                <text
                  x={-10}
                  y={y + barHeight / 2}
                  textAnchor="end"
                  alignmentBaseline="middle"
                  fontSize={14}
                  className="transition-all duration-300 ease-in-out fill-current dark:fill-current font-normal"
                >
                  {entry.Pathology}
                </text>
                <text
                  x={chartWidth + 10}
                  y={y + barHeight / 2}
                  textAnchor="start"
                  alignmentBaseline="middle"
                  fontSize={14}
                  className="transition-all duration-300 ease-in-out fill-current dark:fill-current font-normal"
                >
                  {entry.AUC.toFixed(3)}
                </text>
                {hoveredIndex === index && (
                  <text
                    x={x + barWidth / 2}
                    y={y + barHeight / 2}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fontSize={12}
                    fill="white"
                    className="font-semibold"
                  >
                    {entry.Organ}
                  </text>
                )}
              </g>
            );
          })}
        </g>
      </svg>
    );
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="w-full h-[600px] flex justify-center items-center" ref={containerRef}>
      {dimensions.width > 0 && dimensions.height > 0 && renderLinearChart()}
    </div>
  );
};

// Updated Performance Comparison Chart Component using RadarChart
const PerformanceComparisonChart: React.FC = () => {
  const filePaths = [
    '/performance/AUC_internal.csv',
    '/performance/AUC_external_x.csv',
    '/performance/AUC_external_t.csv',
  ];

  const { data: csvData, error } = useFetchCSVData(filePaths);
  const [data, setData] = useState<any[]>([]);

  // Add this type definition near the top of your file
  type HealthSystem = 'Health System 1' | 'Health System 2' | 'Health System 3';
  // State to manage the visibility of each health system
  const [visibleSystems, setVisibleSystems] = useState<Record<HealthSystem, boolean>>
    ({
    'Health System 1': true,
    'Health System 2': true,
    'Health System 3': true,
  });

  useEffect(() => {
    if (csvData.length === 3) {
      const [parsedInternal, parsedExternalX, parsedExternalT] = csvData;

      // Create a set of all pathologies
      const pathologySet = new Set<string>();
      parsedInternal.forEach((entry: any) => pathologySet.add(entry.Pathology));
      parsedExternalX.forEach((entry: any) => pathologySet.add(entry.Pathology));
      parsedExternalT.forEach((entry: any) => pathologySet.add(entry.Pathology));

      // Create data array
      const dataArray = Array.from(pathologySet).map((pathology) => {
        const internalEntry = parsedInternal.find(
          (entry: any) => entry.Pathology === pathology
        );
        const externalXEntry = parsedExternalX.find(
          (entry: any) => entry.Pathology === pathology
        );
        const externalTEntry = parsedExternalT.find(
          (entry: any) => entry.Pathology === pathology
        );

        // Process Pathology for display
        const pathologyParts = pathology.split('/');
        let displayPathology = pathologyParts[1] || pathologyParts[0];
        displayPathology = toTitleCase(displayPathology);

        return {
          Pathology: displayPathology,
          'Health System 1': internalEntry ? parseFloat(internalEntry.AUC).toFixed(3) : '0.000',
          'Health System 2': externalXEntry ? parseFloat(externalXEntry.AUC).toFixed(3) : '0.000',
          'Health System 3': externalTEntry ? parseFloat(externalTEntry.AUC).toFixed(3) : '0.000',
        };
      });

      setData(dataArray);
    }
  }, [csvData]);

  // Handler to toggle the visibility of health systems
  const handleLegendClick = (dataKey: string) => {
    setVisibleSystems((prev) => ({
      ...prev,
      [dataKey as HealthSystem]: !prev[dataKey as HealthSystem],
    }));
  };

  // Custom legend component
  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className="flex flex-wrap justify-center mt-4">
        {payload.map((entry: any, index: number) => (
          <li
            key={`item-${index}`}
            onClick={() => handleLegendClick(entry.value)}
            style={{
              marginRight: 20,
              cursor: 'pointer',
              opacity: visibleSystems[entry.value as HealthSystem] ? 1 : 0.5,
              textDecoration: visibleSystems[entry.value as HealthSystem] ? 'none' : 'line-through',
            }}
          >
            <span
              style={{
                display: 'inline-block',
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            ></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="w-full h-[500px]">
      <ResponsiveContainer>
        <RadarChart data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="Pathology" tick={{ fontSize: 10 }} />
          <PolarRadiusAxis domain={[0.5, 1]} tick={{ fontSize: 10 }} />
          <Tooltip formatter={(value) => (typeof value === 'number' ? value.toFixed(3) : value)} />
          {visibleSystems['Health System 1'] && (
            <Radar
              name="Health System 1"
              dataKey="Health System 1"
              stroke="#4361ee"
              fill="#4361ee"
              fillOpacity={0.3}
            />
          )}
          {visibleSystems['Health System 2'] && (
            <Radar
              name="Health System 2"
              dataKey="Health System 2"
              stroke="#f72585"
              fill="#f72585"
              fillOpacity={0.3}
            />
          )}
          {visibleSystems['Health System 3'] && (
            <Radar
              name="Health System 3"
              dataKey="Health System 3"
              stroke="#4cc9f0"
              fill="#4cc9f0"
              fillOpacity={0.3}
            />
          )}
          <Legend content={renderLegend} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

const SubgroupChart: React.FC = () => {
  const filePaths = ['/performance/AUC_subgroup.csv'];
  const { data: csvData, error } = useFetchCSVData(filePaths);
  const [data, setData] = useState<CategoryData[]>([]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(1);

  useEffect(() => {
    if (csvData.length === 1) {
      const parsedData: CategoryData[] = [];

      csvData[0].forEach((row: any) => {
        const categoryRaw = row['Subgroup'];
        const category = toTitleCase(categoryRaw);

        const subCategory: SubCategory = {
          name: row['Value'],
          auc: parseFloat(row['AUC']),
        };

        let categoryData = parsedData.find((c) => c.category === category);
        if (!categoryData) {
          categoryData = { category, subCategories: [] };
          parsedData.push(categoryData);
        }
        categoryData.subCategories.push(subCategory);
      });

      setData(parsedData);
    }
  }, [csvData]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) setItemsPerView(1);
      else if (width < 1024) setItemsPerView(2);
      else if (width < 1280) setItemsPerView(3);
      else setItemsPerView(4);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    if (currentIndex + itemsPerView < data.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  const colors = [
    '#4361ee',
    '#4895ef',
    '#3f37c9',
    '#560bad',
    '#b5179e',
    '#f72585',
    '#4cc9f0',
  ];

  return (
    <div className="w-full h-auto relative">
      {currentIndex > 0 && (
        <button
          onClick={handlePrev}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white bg-opacity-70 hover:bg-opacity-90 rounded-full shadow"
        >
          <ArrowLeft className="w-6 h-6 text-gray-700" />
        </button>
      )}

      <div
        ref={scrollContainerRef}
        className="flex flex-nowrap overflow-x-hidden scroll-smooth"
        style={{ scrollBehavior: 'smooth', width: '100%' }}
      >
        {data.slice(currentIndex, currentIndex + itemsPerView).map((categoryData, index) => {
          const chartData = categoryData.subCategories.map(
            (subCategory: SubCategory, idx: number) => ({
              name: subCategory.name,
              auc: subCategory.auc.toFixed(3),
              fill: colors[idx % colors.length],
            })
          );
          return (
            <div key={index} className={`flex-shrink-0 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4`}>
              <div className="flex flex-col items-center">
                <ResponsiveContainer width="100%" height={250}>
                  <RadialBarChart
                    cx="50%"
                    cy="50%"
                    innerRadius="25%"
                    outerRadius="100%"
                    barSize={30}
                    data={chartData}
                    startAngle={90}
                    endAngle={-270}
                  >
                    <RadialBar
                      label={{ position: 'insideStart', fill: '#fff', fontSize: 10 }}
                      background
                      dataKey="auc"
                    />
                    <PolarAngleAxis
                      type="number"
                      domain={[0, 1]}
                      angleAxisId={0}
                      tick={false}
                    />
                  </RadialBarChart>
                </ResponsiveContainer>
                <h3 className="text-lg sm:text-xl font-semibold mt-4 mb-2 text-center">
                  {categoryData.category}
                </h3>
                <div className="mt-2 w-full">
                  {chartData.map((entry: any, idx: number) => (
                    <div
                      key={idx}
                      className="flex items-center justify-between w-full mb-1"
                    >
                      <div className="flex items-center">
                        <div
                          className="w-3 h-3 mr-2"
                          style={{ backgroundColor: entry.fill }}
                        ></div>
                        <span className="text-sm">{entry.name}</span>
                      </div>
                      <span className="text-sm font-semibold ml-4">{entry.auc}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {currentIndex + itemsPerView < data.length && (
        <button
          onClick={handleNext}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white bg-opacity-70 hover:bg-opacity-90 rounded-full shadow"
        >
          <ArrowRight className="w-6 h-6 text-gray-700" />
        </button>
      )}
    </div>
  );
};

export { ConditionPerformanceChart, SubgroupChart, PerformanceComparisonChart };
